import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/HeaderMobile.css"; // Cambié el archivo CSS para la versión móvil
import logoBlack from "../styles/img/logo.png";
import { AuthContext } from "../context/AuthContext";
import comingsoon from "../styles/img/icons/5229336.png";

const HeaderMobile = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  
  const navigate = useNavigate();

  const handleLogout = () => {
    logoutUser();
    setShowMenu(false);
    navigate("/");
  };

  const getAssistLink = () => {
    if (user && user.subdomain) {
      return `https://${user.subdomain}.fothz.com`;
    }
    return "/pricing";
  };

  return (
    <header className="mobile-header">
      <div className="mobile-header-top">
        <a href="/" className="mobile-logo">
          <img src={logoBlack} alt="Logo" />
        </a>
        <button className="menu-toggle" onClick={() => setShowMenu(!showMenu)}>
          ☰
        </button>
      </div>

      {showMenu && (
        <nav className="mobile-menu">
          <ul>
          <li>
              {user ? (
                <div className="mobile-user-menu">
                  <button 
                    onClick={(e) => {
                      e.stopPropagation(); // Evita que el clic cierre el menú principal
                      setShowDropdown4(!showDropdown4);
                    }}
                  >
                    
                    {`Hola ${user.firstName}`} ツ
                  </button>
                  {showDropdown4 && (
                    <div className="mobile-user-dropdown">
                      <div className="mobile-submenu">
                        <ul>
                          <li>
                            <a href="/profile">Ir a perfil</a>
                          </li>
                          <li>
                            <a href={getAssistLink()}>Ir a Fothz Assist</a>
                          </li>
                          <li>
                            <a onClick={handleLogout}>
                              Cerrar Sesión
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <a href="/login" className="mobile-login-button">
                  Iniciar Sesión
                </a>
              )}
            </li>
            <div className="mobile-separator"></div>
            <li>
              <button onClick={() => setShowDropdown1(!showDropdown1)}>
                Fothz Assist
              </button>
              {showDropdown1 && (
                <div className="mobile-submenu">
                  <ul>
                    <li>
                      <a href="/fothz-assist">Plataforma</a>
                    </li>
                    <li>
                      <a href="/pricing">Precio</a>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <div className="mobile-separator"></div>
            <li>
              <button onClick={() => setShowDropdown2(!showDropdown2)}>
                Recursos
              </button>
              {showDropdown2 && (
                <div className="mobile-submenu">
                  <ul>
                    <li>
                      <a href="/support">Centro de ayuda</a>
                    </li>
                    <li>
                      <a>
                        Blog{" "}
                        <img
                          className="icon-comingsoon"
                          src={comingsoon}
                          alt="Fothz Coming Soon"
                        />
                      </a>
                    </li>
                    <li>
                      <a>
                        Documentación Fothz Assist{" "}
                        <img
                          className="icon-comingsoon"
                          src={comingsoon}
                          alt="Fothz Coming Soon"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <div className="mobile-separator"></div>
            <li>
              <button onClick={() => setShowDropdown3(!showDropdown3)}>
                Servicios Personalizados
              </button>
              <div className="mobile-separator"></div>
              {showDropdown3 && (
                <div className="mobile-submenu">
                  <ul>
                    <li>
                      <a href="/web-design">Desarrollo Web</a>
                    </li>
                    <li>
                      <a href="/app">Aplicaciones</a>
                    </li>
                    <li>
                      <a href="/blockchain">Blockchain</a>
                    </li>
                    <li>
                      <a href="/ai">Inteligencia Artificial</a>
                    </li>
                    <li>
                      <a href="/ux-ui">Diseño UX/UI</a>
                    </li>
                    <li>
                      <a href="/cloud">Computación en la Nube</a>
                    </li>
                    <li>
                      <a href="/database">Administración de Bases de Datos</a>
                    </li>
                  </ul>
                </div>
                
              )}
            </li>
            <div className="mobile-buttons">
              <a href="/contact" className="mobile-contact-button">
                Solicita una Demo
              </a>
              {/* <a
                href="https://wa.me/1159526894"
                target="_blank"
                rel="noopener noreferrer"
                className="mobile-whatsapp-button"
              >
                WhatsApp
              </a>*/ }
            </div>

          </ul>
        </nav>
      )}
    </header>
  );
};

export default HeaderMobile;

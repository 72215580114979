// src/pages/Home.js
import React from 'react';
import Apps from '../components/Platform/Apps';
import MainService from '../components/Home/MainService';

const Platform = () => {
  return (
    <>
      <Apps />
      <MainService />      
    </>
  );
};

export default Platform;

import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig'; // Asegúrate de tener configurado axiosInstance
import '../styles/ContactForm.css';

const ContactForm = () => {  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    asunto: '',
    mensaje: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const opcionesAsunto = [
    'Servicio o Desarrollo Personalizado',
    'Entrevista para Demo de Fothz Assist',
    'Otro',
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Enviar los datos al backend
      await axiosInstance.post('/contact', formData);
      setSuccessMessage('¡Tu mensaje ha sido enviado con éxito!');
      setErrorMessage('');
      setFormData({
        nombre: '',
        email: '',
        asunto: '',
        mensaje: '',
      });
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setErrorMessage('Hubo un error al enviar tu mensaje. Por favor, inténtalo de nuevo.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <section className="development-software-header" >
        <div className="title-service" style={{ marginTop: '140px' }}>
          <h1>¡Contáctanos!</h1>
        </div>
      </section>
      <div className="contact-form-container" style={{ marginTop: '20px' }}>
        <h2>Completa el formulario</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nombre:</label>
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Asunto:</label>
            <select
              name="asunto"
              value={formData.asunto}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione una opción</option>
              {opcionesAsunto.map((opcion, index) => (
                <option key={index} value={opcion}>
                  {opcion}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Mensaje:</label>
            <textarea
              name="mensaje"
              value={formData.mensaje}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button className="contact-demo-button" type="submit">Enviar</button>
        </form>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </>
  );
};

export default ContactForm;

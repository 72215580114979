import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import '../styles/CheckoutPage.css'; // Crear archivo para estilos

function CheckoutPage() {
  const location = useLocation();
  const { plan, billingCycle } = location.state || {};
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false); // Añadido para manejar el estado de carga
  const [errorMessage, setErrorMessage] = useState(''); // Añadido para manejar mensajes de error

  useEffect(() => {
    const createSubscription = async () => {
      try {
        const token = localStorage.getItem('token');
        const res = await axios.post('http://localhost:5000/api/billing/subscribe', { plan, billingCycle }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClientSecret(res.data.clientSecret);
      } catch (err) {
        console.error(err);
      }
    };

    if (plan && billingCycle) {
      createSubscription();
    }
  }, [plan, billingCycle]);

  const handlePayment = async (e) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      console.error("Stripe o Elements no están listos.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error("El elemento de la tarjeta no está disponible.");
      return;
    }

    setIsProcessing(true); // Indica que el proceso de pago está en curso
    setErrorMessage(''); // Resetea los mensajes de error

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (error) {
        setErrorMessage("Error en la confirmación del pago. Intenta nuevamente.");
        console.error("Error en la confirmación del pago:", error);
      } else if (paymentIntent.status === 'succeeded') {
        await axios.post('http://localhost:5000/api/billing/confirm', { paymentIntentId: paymentIntent.id }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        navigate('/profile');
      }
    } catch (err) {
      setErrorMessage("Error durante el proceso de pago. Intenta nuevamente.");
      console.error("Error durante el proceso de pago:", err);
    } finally {
      setIsProcessing(false); // Termina el proceso de pago
    }
  };

  return (
    <div className="checkout-container">
      <h1 className="checkout-title">Realiza tu Pago</h1>
      <form onSubmit={handlePayment} className="checkout-form">
        <div className="card-details">
          <label htmlFor="card-element">Detalles de la Tarjeta</label>
          <CardElement id="card-element" className="card-element" />
        </div>

        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <button type="submit" className="checkout-button" disabled={!stripe || !clientSecret || isProcessing}>
          {isProcessing ? 'Procesando...' : 'Pagar'}
        </button>
      </form>
    </div>
  );
}

export default CheckoutPage;

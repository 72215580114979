import React, { useEffect } from "react";
import "../../styles/Platform/Apps.css";
import Contabilidad from "../../styles/img/icons/app/contabilidad.svg";
import Facturacion from "../../styles/img/icons/app/facturacion.svg";
import Informacion from "../../styles/img/icons/app/informacion.svg";
import CRM from "../../styles/img/icons/app/crm.svg";
import PuntoDeVenta from "../../styles/img/icons/app/pdv.svg";
import Conversaciones from "../../styles/img/icons/app/conv.svg";
import Documentos from "../../styles/img/icons/app/docs.svg";
import Proyecto from "../../styles/img/icons/app/proyectos.svg";
import ServicioDeAsistencia from "../../styles/img/icons/app/asistencia.svg";
import SitioWeb from "../../styles/img/icons/app/web.svg";
import MarketingPorCorreoElectronico from "../../styles/img/icons/app/mktmail.svg";
import Compra from "../../styles/img/icons/app/compra.svg";
import Fabricacion from "../../styles/img/icons/app/fabricacion.svg";
import Inventario from "../../styles/img/icons/app/inventario.svg";
import Ventas from "../../styles/img/icons/app/ventas.svg";
import HR from "../../styles/img/icons/app/hr.svg";
import Dashboard from "../../styles/img/icons/app/dashboard.svg";


const Apps = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const appEntries = [
    {
      src: Contabilidad,
      alt: "Fothz Contabilidad icon",
      caption: "Contabilidad",
    },
    {
        src: Facturacion,
        alt: "Fothz Facturación icon",
        caption: "Facturación",
      },
    {
      src: Informacion,
      alt: "Fothz Información icon",
      caption: "Información",
    },
    {
        src: CRM,
        alt: "Fothz CRM icon",
        caption: "CRM",
      },
      {
        src: PuntoDeVenta,
        alt: "Fothz Punto de venta icon",
        caption: "Punto de venta",
      },
      {
        src: Conversaciones,
        alt: "Fothz Conversaciones icon",
        caption: "Conversaciones",
      },
      {
        src: Documentos,
        alt: "Fothz Documentos icon",
        caption: "Documentos",
      },
      {
        src: Proyecto,
        alt: "Fothz Proyecto icon",
        caption: "Proyecto",
      },

      {
        src: ServicioDeAsistencia,
        alt: "Fothz Servicio de asistencia icon",
        caption: "Servicio de asistencia",
      },
      {
        src: SitioWeb,
        alt: "Fothz Sitio web icon",
        caption: "Sitio web",
      },
      {
        src: MarketingPorCorreoElectronico,
        alt: "Fothz Marketing por correo electrónico icon",
        caption: "Marketing por correo electrónico",
      },
      {
        src: Compra,
        alt: "Fothz Compra icon",
        caption: "Compra",
      },
      {
        src: Fabricacion,
        alt: "Fothz Fabricación icon",
        caption: "Fabricación",
      },
      {
        src: Inventario,
        alt: "Fothz Inventario icon",
        caption: "Inventario",
      },
      {
        src: Ventas,
        alt: "Fothz Ventas icon",
        caption: "Ventas",
      },
      {
        src: HR,
        alt: "Fothz HR icon",
        caption: "HR",
      },
      {
        src: Dashboard,
        alt: "Fothz Dashboard icon",
        caption: "Dashboard",
      },
  ];

  return (
    <>
      <section className="fothz-assist-apps">
      <section className="fothz-assist-apps-header">
          <div className="title-service">
            <h1>Fothz Assist</h1>
            <h2>Todas tus herramientas en una sola plataforma!</h2>
          </div>
        </section>
        <section className="development-software-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-xxl-8 text-center">
              <div className="row mt-5 mt-md-6">
                {appEntries.map((entry, index) => (
                  <a
                    key={index}
                    className="x_wd_app_entry col-4 col-sm-3 col-lg-2 text-center mb-3 p-0"
                    href={entry.href}
                  >
                    <figure>
                      <img
                        width="80px"
                        className="img-thumbnail rounded-1 mb-3"
                        src={entry.src}
                        alt={entry.alt}
                        loading="lazy"
                      />
                      <figcaption className="text-truncate small text-o-color-5">
                        {entry.caption}
                      </figcaption>
                    </figure>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        </section>
      </section>
      
    </>
  );
};

export default Apps;

// src/pages/Home.js
import React, { useEffect } from 'react';
import Hero from '../components/Home/Hero';
import Services from '../components/Home/Services';
import Reviews from '../components/Home/Reviews';
import GlobalPlatform from '../components/Home/GlobalPlatform';
import MainService from '../components/Home/MainService';
import InteractiveSection from '../components/Home/InteractiveSection';
import FinalSection from '../components/Home/FinalSection';
import SectionSeparator from '../components/SectionSeparator';
import SectionSeparator2 from '../components/SectionSeparator2';
document.addEventListener('contextmenu', function(event) {
  event.preventDefault();
});

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <MainService />
      <SectionSeparator2 />
      <Reviews />
      <GlobalPlatform />      
      <InteractiveSection />
      <Services />
      <SectionSeparator />
      <FinalSection />
      
    </>
  );
};

export default Home;

// src/components/SectionSeparator.js
import React from 'react';
import '../styles/SectionSeparator.css';

const SectionSeparator = () => {
  return (
    <div className="section-separator">
<svg width="239" height="268" viewBox="0 0 239 268" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M136.923 222.578C138.012 220.829 138.738 218.554 140.287 217.513C142.621 215.99 145.511 215.358 148.182 214.41C148.775 217.017 150.425 220.072 149.655 222.192C146.066 232.544 141.871 242.695 137.854 252.877C136.694 255.826 135.757 258.963 134.346 261.775C132.051 266.318 128.568 267.081 125.235 263.168C118.128 254.84 111.154 246.389 104.655 237.546C103.463 235.915 104.848 232.437 104.972 229.832C107.562 230.34 110.848 230.055 112.557 231.578C115.878 234.442 118.316 238.321 122.058 242.914C125.462 221.836 127.864 202.229 123.718 182.552C117.108 186.164 111.268 190.393 104.839 192.605C92.3413 196.965 80.3981 195.437 70.6663 185.474C60.7613 175.351 59.8641 160.453 68.0966 148.41C75.7617 137.163 90.5364 132.813 103.312 138.056C113.972 142.401 120.818 150.847 127.395 161.688C138.547 133.971 140.837 106.481 137.675 78.3479C134.535 50.2927 123.843 25.0716 106.452 2.43573C107.265 1.90381 108.128 1.39939 108.941 0.867477C110.668 2.5959 112.576 4.22891 114.115 6.18045C135.759 33.4292 144.828 65.0609 145.382 99.26C145.759 123.194 142.88 146.646 132.278 168.602C131.641 169.883 131.391 171.766 131.777 173.085C136.537 188.667 136.963 204.544 135.462 220.604C135.41 221.293 135.386 221.931 135.362 222.57C135.873 222.589 136.412 222.559 136.873 222.551L136.923 222.578ZM104.803 150.086C94.7348 144.305 86.0184 144.742 79.1084 150.21C72.4634 155.432 69.9884 163.396 72.3908 171.623C74.7478 179.694 81.472 185.27 90.4459 186.149C101.765 187.269 111.031 183.093 118.385 174.675C119.323 173.559 119.998 171.257 119.508 170.01C116.02 161.384 111.084 153.855 104.803 150.086Z" fill="#FBB130"/>
</svg>

    </div>
  );
};

export default SectionSeparator;

import React, { useState, useContext } from "react";
import { a, useNavigate } from "react-router-dom";
import "../styles/Header.css";
import logoBlack from "../styles/img/logo.png";
import { AuthContext } from "../context/AuthContext";
import comingsoon from "../styles/img/icons/5229336.png";

const Header = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate(); // Importa useNavigate

  const handleLogout = () => {
    logoutUser();
    setShowDropdown(false); 
    navigate('/'); 
  };
  const getAssista = () => {
    if (user && user.subdomain) {
      return `https://${user.subdomain}.fothz.com`;
    }
    return "/pricing"; // Redirige a la página de selección de plan si no tiene subdominio
  };

  return (
    <header className="header">
      <a href="/" className="logo">
        <img src={logoBlack} alt="Logo" />
      </a>
      <nav>
        <a href="/">Inicio</a>

        <div className="dropdown">
          <button className="dropbtn">Fothz Assist 🠻</button>
          <div className="dropdown-content">
            <div className="dropdown">
              <a href="/fothz-assist">Plataforma</a>
            </div>
            <div className="dropdown">
              <a href="/pricing">Precio</a>
            </div>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn">Recursos 🠻</button>
          <div className="dropdown-content">
            <div className="dropdown">
              <a href="/support">Centro de ayuda</a>
            </div>
            <div className="dropdown">
              <a>
                Blog {" "}
                <img
                  class="icon-comingsoon"
                  src={comingsoon}
                  alt="Fothz Coming Soon"
                />
              </a>
            </div>
            <div className="dropdown">
              <a>
                Documentación Fothz Assist {" "}
                <img
                  class="icon-comingsoon"
                  src={comingsoon}
                  alt="Fothz Coming Soon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn">Servicios Personalizados🠻</button>
          <div className="dropdown-content">
            <div className="dropdown">
              <a href="/web-design" className="sub-dropbtn">
                Desarrollo Web
              </a>
            </div>
            <div className="dropdown">
              <a href="/app" className="sub-dropbtn">
                Aplicaciones
              </a>
            </div>
            <div className="dropdown">
              <a href="/blockchain" className="sub-dropbtn">
                Blockchain
              </a>
            </div>
            <div className="dropdown">
              <a href="/ai" className="sub-dropbtn">
                Inteligencia Artificial
              </a>
            </div>
            <div className="dropdown">
              <a href="/ux-ui" className="sub-dropbtn">
                Diseño UX/UI
              </a>
            </div>
            <div className="dropdown">
              <a href="/cloud" className="sub-dropbtn">
                Computación en la Nube
              </a>
            </div>
            <div className="dropdown">
              <a href="/database" className="sub-dropbtn">
                Administración de Bases de Datos
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className="header-buttons">
        <a
          href="/contact"
          rel="noopener noreferrer"
          className="contact-demo-button"
        >
          Solicita una Demo
        </a>
        <a
          href="https://wa.me/1159526894"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-whatsapp-button"
        >
          WhatsApp
        </a>
        {user ? (
          <div className="user-menu">
            <button onClick={() => setShowDropdown(!showDropdown)}>
              {`Hola ${user.firstName}`} ツ
            </button>
            {showDropdown && (
              <div className="dropdown-content">
                <a href="/profile">Ir a perfil</a>
                <a href={getAssista()}>Ir a Fothz Assist</a>
                <button onClick={handleLogout}>Cerrar Sesión</button>
              </div>
            )}
          </div>
        ) : (
          <a href="/login" className="login-button">
            Iniciar Sesión
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;

import React from "react";

const TermsConditions = () => {
  return (
    <div style={styles.termsContainer}>
      <h1 style={styles.title}>Términos y Condiciones</h1>
      <p style={styles.lastUpdate}>
        Última actualización: 03 de octubre de 2024
      </p>

      <h2 style={styles.heading}>1. Aceptación de los Términos</h2>
      <p style={styles.paragraph}>
        Al acceder y utilizar Fothz, aceptas estar sujeto a estos Términos y
        Condiciones y a nuestras Políticas de Privacidad. Nos reservamos el
        derecho de modificar estos términos en cualquier momento, por lo que te
        recomendamos revisarlos periódicamente.
      </p>

      <h2 style={styles.heading}>2. Uso de la Aplicación</h2>
      <p style={styles.paragraph}>
        Fothz es una herramienta diseñada para proporcionar soluciones
        empresariales mediante una plataforma integrada que incluye CRM,
        herramientas de marketing, IA para CFO y automatización de procesos. Al
        utilizar la aplicación, te comprometes a usarla de manera responsable y
        legal. No está permitido utilizar Fothz para realizar actividades
        ilegales, fraudulentas o dañinas para otros usuarios o terceros.
      </p>

      <h2 style={styles.heading}>3. Registro de Usuario</h2>
      <p style={styles.paragraph}>
        Para acceder a algunas funcionalidades de Fothz, deberás crear una
        cuenta. Es tu responsabilidad mantener la confidencialidad de tu
        información de acceso. Aceptas notificar de inmediato cualquier uso no
        autorizado de tu cuenta.
      </p>

      <h2 style={styles.heading}>4. Propiedad Intelectual</h2>
      <p style={styles.paragraph}>
        Todos los derechos de propiedad intelectual sobre el software, el
        contenido y los elementos visuales de Fothz son propiedad exclusiva de
        Fothz Labs. No se permite la reproducción, distribución o modificación
        de ninguna parte de la aplicación sin autorización previa.
      </p>

      <h2 style={styles.heading}>5. Responsabilidad y Garantías</h2>
      <p style={styles.paragraph}>
        Fothz se proporciona "tal cual" y sin garantías de ningún tipo, ya sean
        explícitas o implícitas. No aseguramos que la aplicación esté disponible
        de manera ininterrumpida, libre de errores o exenta de vulnerabilidades
        de seguridad. Dentro de los límites permitidos por la ley, Fothz Labs no
        será responsable por daños directos, indirectos, incidentales o
        consecuentes derivados del uso o la incapacidad de uso de la aplicación.
      </p>

      <h2 style={styles.heading}>6. Terminación</h2>
      <p style={styles.paragraph}>
        Nos reservamos el derecho de suspender o terminar tu acceso a Fothz en
        cualquier momento, sin previo aviso, si incumples estos términos o si
        consideramos que tu uso es perjudicial para otros usuarios o para la
        empresa.
      </p>

      <h2 style={styles.heading}>7. Ley Aplicable</h2>
      <p style={styles.paragraph}>
        Estos Términos y Condiciones se rigen e interpretan de acuerdo con las
        leyes vigentes en Argentina. Cualquier disputa derivada de estos
        términos será resuelta en los tribunales competentes de Argentina.
      </p>

      <h2 style={styles.heading}>Contacto</h2>
      <p style={styles.paragraph}>
        Si tienes alguna pregunta sobre estos Términos y Condiciones, puedes
        contactarnos en info@fothz.com.
      </p>
    </div>
  );
};

const styles = {
  termsContainer: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "'Open Sans', sans-serif",
    lineHeight: "1.6",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "2.5rem",
    marginBottom: "20px",
    textAlign: "center",
  },
  lastUpdate: {
    fontSize: "1rem",
    marginBottom: "20px",
    textAlign: "center",
    color: "#666",
  },
  heading: {
    fontSize: "1.8rem",
    marginTop: "20px",
    marginBottom: "10px",
    color: "#333",
  },
  paragraph: {
    fontSize: "1rem",
    marginBottom: "15px",
    color: "#555",
  },
  "@media (maxWidth: 768px)": {
    termsContainer: {
      padding: "15px",
    },
    title: {
      fontSize: "2rem",
    },
    heading: {
      fontSize: "1.5rem",
    },
    paragraph: {
      fontSize: "0.9rem",
    },
  },
};

export default TermsConditions;

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/Login/Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loginUser, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  
  const from = location.state?.from || '/profile'; 

  const getAssistLink = () => {
    if (user && user.subdomain) {      
      return `https://${user.subdomain}.fothz.com/my`;
    }
    return "/pricing"; // Redirige a la página de selección de plan si no tiene subdominio
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginUser(email, password);
      console.log('Login successful, checking user data...');
    } catch (error) {
      console.error('Error during login:', error);
      alert('Error al iniciar sesión');
    }
  };

  useEffect(() => {
    // Solo redirige si el usuario está logeado
    if (user) {
      if (user.subdomain) {
        // Abre el subdominio en una nueva pestaña si existe
        window.open(getAssistLink(), '_blank');
        // Redirige al perfil en la misma pestaña
        navigate('/profile', { replace: true });
      } else {
        // Si no tiene subdominio, redirige a precios
        navigate('/pricing', { replace: true });
      }
    }
  }, [user, navigate]);

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h2>Iniciar Sesión</h2>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Correo Electrónico" 
          required 
        />
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Contraseña" 
          required 
        />
        <button type="submit" className="login-button">Ingresar</button>
        <a 
          className="register-button" 
          onClick={() => navigate('/register')}
        >
          Crear una cuenta
        </a>
      </form>
    </div>
  );
};

export default Login;

import React from 'react';
import { FaTools } from 'react-icons/fa';
import "../styles/Blog/Blog.css";

const Blog = () => {
  return (
    <div className="blog-construction">
      <FaTools className="construction-icon" />
      <h2>Blog en Construcción</h2>
      <p>Estamos trabajando para traerte contenido interesante muy pronto. ¡Vuelve pronto!</p>
    </div>
  );
};

export default Blog;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axiosConfig";
import "../styles/Register/Register.css";
import TermsConditions from './TermsConditions';
import '../styles/TermsConditions.css';

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [phone, setPhone] = useState(""); // Nuevo estado para teléfono
  const [country, setCountry] = useState(""); // Nuevo estado para país
  const [howDidYouHear, setHowDidYouHear] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [showTerms, setShowTerms] = useState(false); // Estado para mostrar modal
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }

    try {
      const res = await axios.post("/auth/register", {
        firstName,
        lastName,
        email,
        password,
        company,
        position,
        phone,
        country,
        howDidYouHear,
        acceptTerms,
        newsletter,
      });
      localStorage.setItem("token", res.data.token);
      navigate("/profile"); // Redirigir al dashboard
    } catch (error) {
      console.error(error.response.data);
      alert("Error en el registro");
    }
  };

  const handleGoBack = () => {
    navigate("/login"); // Redirigir a la página de inicio de sesión
  };

  const handleOpenTerms = (e) => {
    e.preventDefault();
    setShowTerms(true); // Mostrar el modal
  };

  const handleCloseTerms = () => {
    setShowTerms(false); // Cerrar el modal
  };

  return (
    <div className="register-container">
      <form onSubmit={handleRegister} className="register-form">
        <a className="back" onClick={handleGoBack}>
          ⬅ Volver
        </a>
        <h2>Registrarse</h2>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Nombre"
          required
        />
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Apellido"
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo Electrónico"
          required
        />
        <input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
          required
        />
        <input
          type={showPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirmar Contraseña"
          required
        />
        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              checked={showPassword}
              onChange={(e) => setShowPassword(e.target.checked)}
            />
            Mostrar Contraseña
          </label>
        </div>
        <input
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          placeholder="Empresa"
          required
        />
        <input
          type="text"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          placeholder="Cargo"
          required
        />
        <input
          type="text"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          placeholder="País"
          required
        />
        <input
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Telefono"
          required
        />
        <input
          type="text"
          value={howDidYouHear}
          onChange={(e) => setHowDidYouHear(e.target.value)}
          placeholder="¿Cómo se enteró de Fothz Labs?"
        />
        
        {/* Checkbox para aceptar términos */}
        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
              required
            />
            Acepto los <a href="/" onClick={handleOpenTerms}>términos y condiciones</a>
          </label>
        </div>

        {/* Modal de términos y condiciones */}
        {showTerms && (
          <div className="modal-overlay" onClick={handleCloseTerms}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="close-button" onClick={handleCloseTerms}>
                X
              </button>
              <TermsConditions />
            </div>
          </div>
        )}

        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              checked={newsletter}
              onChange={(e) => setNewsletter(e.target.checked)}
            />
            Quiero recibir noticias con novedades y promociones
          </label>
        </div>
        <button type="submit" className="register-button">
          Registrar
        </button>
      </form>
    </div>
  );
};

export default Register;

import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';
import '../styles/SupportForm/SupportForm.css'; // Asegúrate de crear este archivo CSS

const SupportForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: 'Problema técnico',
    message: '',
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/support/ticket', formData);
      setMessage('Ticket enviado con éxito.');
      setFormData({
        name: '',
        email: '',
        subject: 'Problema técnico',
        message: '',
      });
    } catch (error) {
      console.error('Error al enviar el ticket:', error);
      setMessage('Hubo un error al enviar el ticket.');
    }
  };

  return (
    <div className="support-form-container">
      <h2>Formulario de Soporte</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit} className="support-form">
        <div className="form-group">
          <label>Nombre:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label>Asunto:</label>
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            className="form-input"
          >
            <option value="Problema técnico">Problema técnico</option>
            <option value="Consulta general">Consulta general</option>
            <option value="Otro">Otro</option>
          </select>
        </div>

        <div className="form-group">
          <label>Mensaje:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="form-textarea"
          ></textarea>
        </div>

        <button class="contact-demo-button" type="submit">Enviar Ticket</button>
      </form>
    </div>
  );
};

export default SupportForm;

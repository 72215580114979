import React from 'react';
import '../../styles/Home/MainService.css';

// Importar imágenes
import projectManagementImage from '../../styles/img/images-assist/fothz-dashboard.png';
import collaborationImage from '../../styles/img/images-assist/fothz-conv.png';
import documentManagementImage from '../../styles/img/images-assist/fothz-crm.png';
import financialManagementImage from '../../styles/img/images-assist/fothz-facturas.png';
import hrImage from '../../styles/img/images-assist/fothz-rrhh.png';
import marketingAutomationImage from '../../styles/img/images-assist/fothz-email-marketing.png';
import analyticsImage from '../../styles/img/images-assist/fothz-web.png';

const MainService = () => {
  return (
    <>
    <section>
      <div className="svg-container">
        <svg
          class="svg-wave"
          viewBox="0 0 1620 128"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z"
            fill="#FFFBF4"
          ></path>
        </svg>{" "}
      </div>
    </section>
    <section className="main-service">
      <div className="stage stage-intro">
        <h2>Todo tu negocio, en Fothz Assist</h2>
        <p>Fothz Assist es un software destinado tanto grandes Empresas como Startups, es una herramienta con multifunciones que busca facilitar las tareas diarias. Con Fothz Assist van a encontrar un software que los va a impulsar a mejorar constantemente. <br/> ¡Te contamos brevemente algunas de las principales funciones! </p>
      </div>

      <div className="stage stage-feature">
        <div className="feature-content">
          <div className="text">
            <h3>Dashboard</h3>
            <p>No hay necesidad de exportar e importar datos, los que se encuentran en tu base de datos te permiten crear gráficos y hojas de cálculo. <b class="highlight-yellow">Tu información siempre estará actualizada.</b></p>
          </div>
          <div className="media">
            <img src={projectManagementImage} alt="Gestión de Proyectos" />
          </div>
        </div>
      </div>

      <div className="stage stage-feature alt">
        <div className="feature-content">
          <div className="media">
            <img src={collaborationImage} alt="Fothz - Colaboración y Comunicación" />
          </div>
          <div className="text">
            <h3>Comunícate con facilidad</h3>
            <p>Olvídate de usar varias aplicaciones para comunicarte o de experimentar errores en tus llamadas.</p>
          </div>
        </div>
      </div>

      <div className="stage stage-feature">
        <div className="feature-content">
          <div className="text">
            <h3>CRM</h3>
            <p>Nuestra herramienta de CRM te permite gestionar y fortalecer relaciones con clientes de manera eficiente. Con funciones avanzadas de seguimiento, automatización de ventas y análisis de datos, tendrás toda la información centralizada para tomar decisiones informadas y mejorar la experiencia del cliente.</p>
          </div>
          <div className="media">
            <img src={documentManagementImage} alt="Fothz - Gestión de Documentos" />
          </div>
        </div>
      </div>

      <div className="stage stage-feature alt">
        <div className="feature-content">
          <div className="media">
            <img src={financialManagementImage} alt="Fothz - Gestión Financiera" />
          </div>
          <div className="text">
            <h3>Gestión Financiera</h3>
            <p><b class="highlight-yellow">Facturación y contabilidad</b>, integración con bancos y servicios de pago, reportes financieros y análisis de gastos.</p>
          </div>
        </div>
      </div>

      <div className="stage stage-feature">
        <div className="feature-content">
          <div className="text">
            <h3>Recursos Humanos</h3>
            <p>La aplicación de Empleados no solo es un directorio de personal, sino que también te proporciona muchas funciones para recursos humanos.</p>
          </div>
          <div className="media">
            <img src={hrImage} alt="Fothz - Recursos Humanos" />
          </div>
        </div>
      </div>

      <div className="stage stage-feature alt">
        <div className="feature-content">
          <div className="media">
            <img src={marketingAutomationImage} alt="Fothz - Automatización de Marketing" />
          </div>
          <div className="text">
            <h3>E-mail Marketing</h3>
            <p>Ofrecemos <b class="highlight-yellow">Marketing por correo electrónico </b> el cual es útil para que cualquier persona cree correos electrónicos profesionales en segundos y los envíe a la audiencia adecuada.</p>
          </div>
        </div>
      </div>

      <div className="stage stage-feature">
        <div className="feature-content">
          <div className="text">
            <h3>Tu sitio web profesional en minutos</h3>
            <p>La aplicación <b class="highlight-yellow">Sitio web</b> está cambiando lo que varias personas piensan acerca del diseño web. Crea, gestiona y personaliza tu sitio web desde una interfaz intuitiva y fácil de usar.</p>
          </div>
          <div className="media">
            <img src={analyticsImage} alt="Fothz - Análisis y Reportes" />
          </div>
        </div>
      </div>

      <div className="stage stage-summary">
        <h2>¿Queres solicitar una Demo?</h2>
        <p>Te invitamos a que nos contactes para que conozcas todas las funciones de Fothz Assist.</p>
        <a href="/contact" class="contact-button">Solicitar Demo</a>
      </div>
    </section>
          <section>
          <div class="svg-container">
            <svg
              class="css-129n9a0"
              width="1620"
              height="128"
              viewBox="0 0 1620 128"
              xmlns="http://www.w3.org/2000/svg"
              fill="#FFFBF4"
            >
              <path d="M0 0H1620C1620 0 1578 128 1109 128C640 128 74.2379 35.009 0 0Z"></path>
            </svg>
          </div>
        </section>
      </>
  );
};

export default MainService;

import React from 'react';
import '../../styles/Home/Hero.css';
import gifBg from '../../assets/fondo.gif'; // Asegúrate de tener la ruta correcta del GIF

const Hero = () => {
  return (
    <div className="hero-container">
      <img
        alt="Fothz Labs Hero"
        className="gif-bg" // Cambié el nombre de la clase para reflejar el nuevo fondo
        src={gifBg}
      />
      <div className="overlay"></div>
      <div className="hero-content">
        <h1 className="highlight-yellow">Potencia tu negocio ツ</h1>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import "../../styles/Home/Services.css";
import {
  FaLaptopCode,
  FaMobileAlt,
  FaBitcoin,
  FaBrain,
  FaPalette,
  FaCloud,
  FaDatabase,
  FaServer,
} from "react-icons/fa";

const Services = () => {
  return (
    <>
      <section>
        <div className="svg-container">
          <svg
            class="css-vxjk88"
            width="1620"
            height="128"
            viewBox="0 0 1620 128"
            xmlns="http://www.w3.org/2000/svg"
            fill="#f4f4f4"
          >
            <path d="M0 128V74.6535L91.3465 48.8153C101.082 46.0615 111.106 44.4536 121.215 44.0243L1146.47 0.489582C1154.14 0.163835 1161.83 0.518387 1169.44 1.54908L1620 62.5824V128H0Z"></path>
          </svg>
        </div>
      </section>
      <section className="services-section">
        <h2>Tambien te podemos ayudar con estos servicios :)</h2>
        <div className="services-container">
          <div className="service">
            <FaLaptopCode className="service-icon" />
            <h3>Desarrollo Web</h3>
            <p>
              Desarrollamos sitios web, PWAs (Progressive Web Apps), e-commerce
              y todo tipo de sistemas accesibles desde cualquier navegador.
            </p>
            <a href="/web-design" className="service-button">
              Más Detalles
            </a>
          </div>
          <div className="service">
            <FaMobileAlt className="service-icon" />
            <h3>Aplicaciones</h3>
            <p>
              Creamos aplicaciones móviles nativas y multiplataforma para iOS y
              Android, garantizando un rendimiento óptimo y una experiencia de
              usuario excepcional.
            </p>
            <a href="/app" className="service-button">
              Más Detalles
            </a>
          </div>
          <div className="service">
            <FaBitcoin className="service-icon" />
            <h3>Blockchain</h3>
            <p>
              Ofrecemos soluciones basadas en blockchain, desde desarrollo de
              contratos inteligentes hasta implementación de redes
              descentralizadas.
            </p>
            <a href="/blockchain" className="service-button">
              Más Detalles
            </a>
          </div>
          <div className="service">
            <FaBrain className="service-icon" />
            <h3>Inteligencia Artificial</h3>
            <p>
              Implementamos soluciones de inteligencia artificial, desde
              chatbots hasta sistemas de aprendizaje automático, para optimizar
              procesos y tomar decisiones más inteligentes.
            </p>
            <a href="/ai" className="service-button">
              Más Detalles
            </a>
          </div>
          <div className="service">
            <FaPalette className="service-icon" />
            <h3>Diseño UX/UI</h3>
            <p>
              Diseñamos interfaces de usuario intuitivas y atractivas,
              garantizando una experiencia de usuario excepcional en todas las
              plataformas.
            </p>
            <a href="/ux-ui" className="service-button">
              Más Detalles
            </a>
          </div>
          <div className="service">
            <FaCloud className="service-icon" />
            <h3>Computación en la Nube</h3>
            <p>
              Proveemos soluciones de computación en la nube, incluyendo
              infraestructura como servicio (IaaS), plataforma como servicio
              (PaaS) y software como servicio (SaaS).
            </p>
            <a href="/cloud" className="service-button">
              Más Detalles
            </a>
          </div>
          <div className="service">
            <FaDatabase className="service-icon" />
            <h3>Administración de Bases de Datos</h3>
            <p>
              Ofrecemos servicios de diseño, implementación y administración de
              bases de datos para garantizar la integridad y disponibilidad de
              tus datos.
            </p>
            <a href="/database" className="service-button">
              Más Detalles
            </a>
          </div>
          <div className="service">
            <FaServer className="service-icon" />
            <h3>DevOps</h3>
            <p>
              Implementamos prácticas de DevOps para mejorar la colaboración
              entre equipos de desarrollo y operaciones, automatizando procesos
              y mejorando la eficiencia.
            </p>
            <a href="/devops" className="service-button">
              Más Detalles
            </a>
          </div>
        </div>
      </section>
      <section>
        <div class="svg-container">
          <svg
            class="css-vxjk88"
            width="1620"
            height="128"
            viewBox="0 0 1620 128"
            xmlns="http://www.w3.org/2000/svg"
            fill="#f4f4f4"
          >
            <path d="M1620 0V53.3465L1528.65 79.1847C1518.92 81.9385 1508.89 83.5464 1498.79 83.9757L473.53 127.51C465.858 127.836 458.173 127.482 450.564 126.451L0 65.4176V0H1620Z"></path>
          </svg>
        </div>
      </section>
    </>
  );
};

export default Services;

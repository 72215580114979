// src/pages/ServiceWebDesign.js
import React, { useEffect } from "react";
import "../../styles/Services/DevelopmentSoftware.css";
// Importa los videos
import FinalSection from "../Home/FinalSection";

const ServiceCloud = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="development-software">
        <section className="development-software-header">
          <div className="title-service">
            <h1>Computación en la Nube</h1>
          </div>
        </section>
        <section className="development-software-content">
          <h2>Desbloquea el poder de la nube</h2>
          <p>
            La computación en la nube es una tecnología que permite a las empresas almacenar y procesar datos en servidores remotos, en lugar de en sus propios servidores o dispositivos personales. Esto significa que las empresas pueden acceder a sus datos y aplicaciones desde cualquier lugar y en cualquier momento, lo que les permite ser más flexibles y eficientes.
          </p>

          <div className="arrow-left">
            <svg
              width="127"
              height="225"
              viewBox="0 0 177 265"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.6609 252.913C26.9982 250.913 22.3461 248.492 17.4805 247.088C15.266 246.47 11.1171 246.988 10.217 248.494C8.08411 251.854 10.6409 254.327 14.0978 255.293C21.7134 257.419 29.3655 259.384 37.0227 261.4C38.6394 261.816 40.381 261.905 41.8624 262.544C48.8485 265.729 51.589 264.249 51.6232 256.693C51.6948 246.877 51.7664 237.061 51.1101 227.318C50.9342 225.028 48.1646 221.475 46.1268 221.048C41.838 220.165 40.7551 224.049 40.6913 227.622C40.6113 232.613 40.7392 237.584 40.8094 244.081C31.5548 232.362 25.3988 220.65 21.0472 208.075C3.36551 157.123 10.508 108.155 34.2655 60.992C41.1492 47.3499 51.8891 37.1 67.8978 34.4025C71.6673 33.7645 75.5975 34.2119 80.6821 34.1246C71.8231 46.9145 65.6677 59.4348 63.7071 73.4778C62.6592 80.8731 62.209 88.4712 62.7883 95.8617C63.5022 104.603 67.3931 112.03 76.5993 114.836C85.9718 117.731 94.073 114.72 100.476 107.84C110.145 97.3823 114.054 84.4564 114.19 70.543C114.389 53.5809 107.61 39.8323 93.0418 29.5878C116.93 6.38264 144.856 -0.0207063 176.447 6.11382C176.552 5.57892 176.651 4.99207 176.755 4.45717C174.852 3.8076 173.038 2.99186 171.052 2.5604C141.042 -4.04644 114.348 2.60123 91.2768 22.945C88.3332 25.5463 86.0505 26.3509 82.0166 25.3893C67.1178 21.8392 53.5625 25.9706 42.3548 35.7427C35.6875 41.5477 29.238 48.485 25.2888 56.2745C-0.071148 106.325 -7.95309 158.409 11.3911 212.342C16.4024 226.267 24.5595 239.092 31.5672 253.027L31.6609 252.913ZM86.514 37.3197C113.807 53.0095 106.726 91.0091 92.2112 103.419C82.6314 111.612 71.9181 107.383 70.3013 94.3259C67.7364 73.3384 74.1647 54.6013 86.5088 37.2678L86.514 37.3197Z"
                fill="#ced4da"
              />
            </svg>
          </div>
        </section>
      </div>
      <FinalSection />
    </>
  );
};

export default ServiceCloud;

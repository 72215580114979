// src/components/FinalSection.js
import React from 'react';
import '../../styles/Home/FinalSection.css';

const FinalSection = () => {
  return (
    <section className="final-section">
      <h2 class="text-red">Potencia </h2>
      <h2>tu camino hacia el éxito.</h2>
      <div className="contact">
      <a class="contact-button" href="/contact">¡Contactanos!</a>
      </div>
    </section>
  );
};

export default FinalSection;

import React, { useState } from 'react';
import '../../styles/Home/Reviews.css';

const Reviews = () => {
  const [currentIndex] = useState(0);

  const reviews = [
    { text: "El servicio que ofrece esta empresa es simplemente excelente. Desde el primer contacto hasta la entrega final, la experiencia ha sido de primer nivel. Todo el proceso fue manejado de manera eficiente y profesional, y me sentí acompañado en cada paso. Los resultados superaron mis expectativas, y puedo decir sin duda que esta es una empresa en la que se puede confiar plenamente. Muy recomendable para quienes buscan calidad y compromiso en el servicio.", author: "CEO - Empresa de Recursos Humanos" },
    { text: "La plataforma que ofrece esta empresa es una de las más intuitivas y fáciles de usar que he encontrado en el mercado. Desde la interfaz limpia hasta las funciones bien organizadas, todo está diseñado para facilitar la vida del usuario. Incluso si no tienes mucha experiencia en este tipo de herramientas, encontrarás que todo es accesible y directo. Además, la empresa sigue innovando y mejorando la plataforma, lo que demuestra su compromiso con la satisfacción del cliente.", author: "Ejecutivo Comercial - Empresa Tecnológica" },
  ];

  return (
    <section className="reviews-section">
      <div className="reviews-container">
        {reviews.slice(currentIndex, currentIndex + 5).map((review, index) => (
          <div className="review" key={index}>
            <p>"{review.text}"</p>
            <h4>{review.author}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Reviews;

// src/components/Footer.js
import React from 'react';
import '../styles/Footer.css';
import fothzLogo from '../styles/img/logo-white.png'; // Asegúrate de tener esta imagen en tu proyecto
import twitterLogo from '../styles/img/icons/Twitter-40.svg'; // Asegúrate de tener esta imagen en tu proyecto
import linkedinLogo from '../styles/img/icons/LinkedIn-40.svg'; // Asegúrate de tener esta imagen en tu proyecto
import instagramLogo from '../styles/img/icons/Instagram-40.svg'; // Asegúrate de tener esta imagen en tu proyecto

const Footer = () => {
  return (
    <>
    <section>
      <div className="svg-container">
        <svg
          class="svg-wave"
          viewBox="0 0 1620 128"
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: '-1px' }} // Add margin to the svg
        >
          <path
            d="M1620 128H0C0 128 42 -7.67075e-05 511 0C980 7.67075e-05 1545.76 92.991 1620 128Z"
            fill="#333"
          ></path>
        </svg>{" "}
      </div>
    </section>
      <footer className="footer-section">
        <div className="footer-container">
          <div className="footer-logo-social">
            <img src={fothzLogo} alt="Fothz Logo" className="footer-logo" />
            <div className="footer-social">
              <a href="https://www.linkedin.com/company/fothzlabs" target="_blank" rel="noopener noreferrer">
                <img src={linkedinLogo} alt="LinkedIn" className="social-logo" />
              </a>
              <a href="https://instagram.com/fothzlabs" target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" className="social-logo" />
              </a>
            </div>
          </div>
          </div>
        <div className="footer-bottom">
          <p>&copy; 2024. Todos los derechos reservados.</p>
          {/*<ul>
            <li><a href="#">Aviso legal</a></li>
            <li><a href="#">Política de privacidad</a></li>
            <li><a href="#">Términos del servicio</a></li>
            <li><a href="#">Whistleblower Policy</a></li>
            <li><a href="#">Política de cookies</a></li>
            <li><a href="#">Configuración de las cookies</a></li>
          </ul> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axiosConfig';
import '../styles/VerifyEmail.css'; // Archivo CSS para los estilos personalizados

const VerifyEmail = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const verifyUserEmail = async () => {
            const query = new URLSearchParams(location.search);
            const token = query.get('token');

            if (token) {
                try {
                    const res = await axios.get(`/auth/verify-email?token=${token}`);
                    setMessage(res.data.message);
                    setStatus('success');
                    // Redirigir después de un tiempo
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } catch (error) {
                    setMessage('Error verificando el email. Por favor, intenta nuevamente.');
                    setStatus('error');
                }
            } else {
                setMessage('Token no válido.');
                setStatus('error');
            }
        };

        verifyUserEmail();
    }, [location, navigate]);

    return (
        <div className="verify-container">
            {status === 'loading' && (
                <div className="loading-spinner">
                    <div className="spinner"></div>
                    <p>Verificando tu email, por favor espera...</p>
                </div>
            )}
            {status === 'success' && (
                <div className="verify-success">
                    <h2>¡Verificación exitosa!</h2>
                    <p>{message}</p>
                    <p>Serás redirigido al login en unos segundos...</p>
                </div>
            )}
            {status === 'error' && (
                <div className="verify-error">
                    <h2>Error en la verificación</h2>
                    <p>{message}</p>
                    <p>Por favor, intenta nuevamente o contacta al soporte.</p>
                </div>
            )}
        </div>
    );
};

export default VerifyEmail;

// src/components/GlobalPlatform.js
import React from 'react';
import '../../styles/Home/GlobalPlatform.css';
import videoSrc from '../../styles/img/images-assist/CRM.mp4';

const GlobalPlatform = () => {
  return (
    <section className="global-platform-section">
      <div className="content">
        <h3>PLATAFORMA MULTIFUNCIONAL</h3>
        <h2>Todas nuestras herramientas son intuitivas y dinámicas</h2>
        <p>
          Fothz Assist está diseñado para adaptarse a organizaciones de todos los tamaños, desde pequeños equipos hasta grandes corporaciones. Con una interfaz intuitiva y funcionalidades avanzadas, nuestra herramienta te permite desarrollar tu web, gestionar proyectos con eficiencia, y facilitar la comunicación interna entre empleados.
        </p>
        <p>
          ¿Necesitas vender en línea? Nuestra plataforma ofrece soluciones integrales para comercio electrónico. ¿Buscas una forma de mantener un registro detallado de tus clientes y ventas? El CRM de Fothz Assist te proporciona todo lo que necesitas para gestionar tus relaciones comerciales con facilidad.
        </p>
        <p>
          Desde la planificación y ejecución de proyectos hasta la optimización de tu presencia digital y la gestión de interacciones con clientes, Fothz Assist está aquí para transformar la manera en que tu empresa opera. Con Fothz Assist, obtendrás una solución flexible y poderosa que se adapta a tus necesidades, sin importar el tamaño o la complejidad de tu organización.
        </p>
      </div>
      <div className="section-separator">
      <svg class="mobile-hide" width="92" height="46" viewBox="0 0 92 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M73.7341 26.7266C57.8441 27.5271 43.6117 28.2705 29.3222 28.8995C21.5486 29.2426 13.8323 29.5856 6.05877 29.5856C4.00107 29.5856 1.94338 28.3277 0 26.3263C23.3206 20.5511 47.2127 20.8942 71.6192 17.635C68.8185 13.9754 66.1321 11.4023 64.6459 8.20019C63.7314 6.19888 63.6171 2.36778 64.8746 1.167C66.1892 -0.0909695 70.3618 -0.262516 71.905 0.938271C78.5925 6.02732 85.0514 11.5738 90.9959 17.5206C92.2534 18.8357 92.139 23.5245 90.7672 25.0684C85.3944 31.2438 79.3928 36.9047 73.5055 42.6227C70.8762 45.1387 67.1609 46.9684 64.7603 43.2517C63.6171 41.4791 64.5888 37.4193 65.8463 35.1321C67.2752 32.4446 70.1331 30.5005 73.7913 26.7838L73.7341 26.7266Z" fill="#017E84"/>
</svg>



    </div>
      <div className="video-container">
        <video src={videoSrc} autoPlay loop muted playsInline className="video-content" alt="Fothz Global"/>
      </div>
    </section>
  );
};

export default GlobalPlatform;

import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          console.log('Fetching user profile with token:', token);
          const res = await axiosInstance.get('/auth/profile', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log('User profile fetched:', res.data);
          setUser(res.data);
        } catch (err) {
          console.error('Error fetching user profile', err);
          if (err.response && err.response.status === 401) {
            // Si el token es inválido, redirige al login y elimina el token
            localStorage.removeItem('token');
            setUser(null);
            window.location.href = '/login';
          }
        }
      }
    };

    fetchUser();
  }, []); // Asegúrate de que fetchUser se llama al montarse el componente

  const loginUser = async (email, password) => {
    try {
      const res = await axiosInstance.post('/auth/login', { email, password });
      localStorage.setItem('token', res.data.token);
      setUser(res.data.user);
    } catch (err) {
      console.error('Error al iniciar sesión', err);
      throw err;
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loginUser, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import '../styles/Profile/Profile.css';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Estado para editar el perfil
  const [updatedUser, setUpdatedUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const res = await axiosInstance.get('/auth/profile', {
          headers: {
            Authorization: `Bearer ${token}`, // Comillas invertidas corregidas
          },
        });
        setUser(res.data);
        setUpdatedUser(res.data); // Inicializar los campos de edición con los datos actuales
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handleProfileUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.put('/auth/update-profile', updatedUser, {
        headers: {
          Authorization: `Bearer ${token}`, // Comillas invertidas corregidas
        },
      });
      setUser(updatedUser); // Actualizar la visualización del perfil
      setIsEditing(false); // Salir del modo de edición
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (!user) {
    return (
      <div className="profile-spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }
  

  return (
    <div className="profile-container">
      <h2>Perfil de Usuario</h2>

      <div className="profile-section">
        <label>Nombre:</label>
        {isEditing ? (
          <input
            type="text"
            name="firstName"
            value={updatedUser.firstName}
            onChange={handleInputChange}
          />
        ) : (
          <p>{user.firstName} {user.lastName}</p>
        )}
      </div>

      <div className="profile-section">
        <label>Email:</label>
        <p>{user.email}</p>
      </div>

      <div className="profile-section">
        <label>Empresa:</label>
        {isEditing ? (
          <input
            type="text"
            name="company"
            value={updatedUser.company}
            onChange={handleInputChange}
          />
        ) : (
          <p>{user.company}</p>
        )}
      </div>
      <div className="profile-section">
        <label>Plan:</label>
        <p>{user.planid}</p>
      </div>
      <div className="profile-section">
        <label>Estado: </label>
        <p>{user.statusplan}</p>
      </div>
      <div className="profile-section">
        <label>Cargo:</label>
        {isEditing ? (
          <input
            type="text"
            name="position"
            value={updatedUser.position}
            onChange={handleInputChange}
          />
        ) : (
          <p>{user.position}</p>
        )}
      </div>      


      <div className="profile-actions">
      {/*<button className="manage-subscription-button">Gestionar Suscripción</button>
        <button className="view-payments-button">Ver Pagos</button>
        <a href="/support" className="support-ticket-button">Enviar Ticket de Soporte</a>*/ }
      </div>
    </div>
  );
};

export default Dashboard;

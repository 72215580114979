// src/components/ErrorBoundary.js
import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que se muestre la interfaz de repuesto.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Puedes registrar el error en algún servicio o en la consola
    console.error("Error capturado por ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Puedes personalizar el mensaje que verá el usuario.
      return (
        <div className="error-boundary">
          <h2>Algo salió mal.</h2>
          <p>Por favor, recarga la página o intenta más tarde.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import '../../styles/Home/InteractiveSection.css';
import { ReactComponent as WebIcon } from '../../styles/img/icons/web.svg';
import { ReactComponent as DesktopIcon } from '../../styles/img/icons/app.svg';
import { ReactComponent as CustomizableIcon } from '../../styles/img/icons/dev.svg';


const InteractiveSection = () => {
  return (
    <section className="interactive-section">
      <h2>Usa Nuestra Plataforma como Prefieras</h2>
      <div className="interactive-content">
        <div className="interactive-item">
          <WebIcon className="interactive-icon" />
          <div className="interactive-text">
            <h3>Directamente desde la Web</h3>
            <p>Nuestra plataforma es accesible directamente desde cualquier navegador, sin necesidad de instalaciones complicadas.</p>
          </div>
        </div>
        <div className="interactive-item">
          <DesktopIcon className="interactive-icon" />
          <div className="interactive-text">
            <h3>Preparada para Desktop</h3>
            <p>Ofrecemos versiones de escritorio para un rendimiento óptimo y una experiencia de usuario mejorada.</p>
          </div>
        </div>
        <div className="interactive-item">
          <CustomizableIcon className="interactive-icon" />
          <div className="interactive-text">
            <h3>Personalizable</h3>
            <p>Contacta con nosotros para integrar distintas funciones según las necesidades de tu empresa.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InteractiveSection;

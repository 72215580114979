import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./styles/App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BackToTopButton from "./components/BackToTopButton";
import Home from "./pages/Home";
import Platform from "./pages/Platform";
import Pricing from "./pages/Pricing";
import Blog from "./pages/Blog";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import Verify from "./components/Verify";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import CheckoutPage from "./components/CheckoutPage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ContactForm from "./components/ContactForm";
import SupportForm from "./components/SupportForm";
import NotFound from "./components/NotFound";
import WebDesign from "./components/Services/ServiceWebDesign";
import ServiceApp from "./components/Services/ServiceApp";
import ServiceBlockchain from "./components/Services/ServiceBlockchain";
import ServiceAI from "./components/Services/ServiceAI";
import ServiceUXUI from "./components/Services/ServiceUXUI";
import ServiceCloud from "./components/Services/ServiceCloud";
import ServiceDatabase from "./components/Services/ServiceDatabase";
import ErrorBoundary from "./components/ErrorBoundary"; 
import HeaderMobile from "./components/HeaderMobile";


const stripePromise = loadStripe(
  "pk_live_51PvgDJL9TUmnJq0yUxQYSRpcDePt5KfVhrocCJnAa8jZ7fRlGRrIAt5UKscz8lHqVIslDejPHx3jqvYdu9N68hQP00KnfHSW00"
);

function AppContent() {
  const location = useLocation(); // Hook to access the current path

  // Define the routes where the footer should not appear
  const noFooterRoutes = ['/register', '/login'];

  return (
    <div className="App">
      <Header />
      <HeaderMobile />
      <ErrorBoundary>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fothz-assist" element={<Platform />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/support"
            element={
              <PrivateRoute>
                <SupportForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/pricing"
            element={
              <PrivateRoute>
                <Pricing />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <PrivateRoute>
                <Elements stripe={stripePromise}>
                  <CheckoutPage />
                </Elements>
              </PrivateRoute>
            }
          />
          <Route path="/verify-email" element={<Verify />} />
          <Route path="/web-design" element={<WebDesign />} />
          <Route path="/app" element={<ServiceApp />} />
          <Route path="/blockchain" element={<ServiceBlockchain />} />
          <Route path="/ai" element={<ServiceAI />} />
          <Route path="/ux-ui" element={<ServiceUXUI />} />
          <Route path="/cloud" element={<ServiceCloud />} />
          <Route path="/database" element={<ServiceDatabase />} />
        </Routes>
      </main>
      </ErrorBoundary>

      {/* Conditionally render Footer */}
      {!noFooterRoutes.includes(location.pathname) && <Footer />}
      
      <BackToTopButton />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;

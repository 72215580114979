import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Platform/Pricing.css";
import checkIcon from "../styles/effects/yes.webp";
import crossIcon from "../styles/effects/no.png";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [annual, setAnnual] = useState(false);
  const navigate = useNavigate();

  const handleSwitch = () => {
    setAnnual(!annual);
  };

  const basicPrice = annual ? "$90" : "$99";
  const premiumPrice = annual ? "$110" : "$120";
  const basicDiscounted = annual ? "$99" : null;
  const premiumDiscounted = annual ? "$120" : null;

  const handleSelectPlan = (plan) => {
    const billingCycle = annual ? 'Annual' : 'Monthly';
    navigate('/checkout', { state: { plan, billingCycle } });
  };

  return (
    <div className="pricing">
      <h1 className="pricing-header">Nuestros Precios</h1>

      <div className="switch-container">
        <span>Mensual</span>
        <label className="switch">
          <input type="checkbox" checked={annual} onChange={handleSwitch} />
          <span className="slider"></span>
        </label>
        <span>Anual</span>
      </div>

      <div className="pricing-table">
        <div className="pricing-plan basic">
          <h2>Plan Básico</h2>
          <p className="price">
            {basicPrice}
            {annual && (
              <span className="discounted">{basicDiscounted}</span>
            )}
            <span className="per-user">/mes</span>
            <br></br>
            <span className="per-user-red">Solicite una cotización para la instalación.</span>
          </p>
          <ul>
            <li><img src={checkIcon} alt="check" /> Elegí 5 herramientas a elección</li>
            <li><img src={checkIcon} alt="check" /> Hosting y subdominio incluido</li>
            <li><img src={checkIcon} alt="check" /> Soporte</li>
            <li><img src={checkIcon} alt="check" /> Integración de APIs</li>
            <li><img src={checkIcon} alt="check" /> Hasta 5 usuarios</li>
          </ul>
          <a href="/contact" className="select-plan">Contactar</a>
          {/* <button
            className="select-plan"
            onClick={() => handleSelectPlan('Basic')}
          >
            Seleccionar
          </button>*/ }
        </div>

        <div className="pricing-plan premium">
          <h2>Plan Premium</h2>
          <p className="price">
            {premiumPrice}
            {annual && (
              <span className="discounted">{premiumDiscounted}</span>
            )}
            <span className="per-user">/mes</span>
            <br></br>
            <span className="per-user-red">Solicite una cotización para la instalación.</span>
          </p>
          <ul>
            <li><img src={checkIcon} alt="check" /> Todas las herramientas disponibles</li>
            <li><img src={checkIcon} alt="check" /> Hosting y subdominio incluido</li>
            <li><img src={checkIcon} alt="check" /> Soporte premium 24/7</li>
            <li><img src={checkIcon} alt="check" /> Integración con APIs</li>
            <li><img src={checkIcon} alt="check" /> Usuarios ilimitados</li>
            <li><img src={checkIcon} alt="check" /> Ejecutivo de Cuentas</li>
          </ul>
          <a href="/contact" className="select-plan">Contactar</a>
          {/*<button
            className="select-plan"
            onClick={() => handleSelectPlan('Premium')}
          >
            Seleccionar
          </button>*/ }
        </div>

        <div className="pricing-plan custom">
          <h2>Plan Personalizado</h2>
          <p className="price">Contáctanos</p>
          <ul>
            <li><img src={checkIcon} alt="check" /> Solución a medida</li>
            <li><img src={checkIcon} alt="check" /> Funcionalidades personalizadas</li>
            <li><img src={checkIcon} alt="check" /> Integración con herramientas avanzadas</li>            
            <li><img src={checkIcon} alt="check" /> Asesoramiento personalizado con empresas expertas en distintas industrias y servicios</li>
            <li><img src={checkIcon} alt="check" /> SEO y análisis</li>
            <li><img src={checkIcon} alt="check" /> Y mucho más !</li>
          </ul>
          <a href="/contact" className="select-plan">Contactar</a>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
